import React from 'react'

import OverlayBottom from 'components/OverlayBottom'
import Heading from 'components/Heading'
import Markdown from 'components/styled/Markdown'

export default ({ src, title, content, color }) => (
  <OverlayBottom src={src} imageOverlayColor={color}>
    <Heading color={color}>{title}</Heading>
    <Markdown children={content} />
  </OverlayBottom>
)
