import React from 'react'
import styled from 'styled-components'

import { ResponsiveCarousel } from 'components/Carousel'
import Heading from 'components/Heading'

import ProgramCard from './ProgramCard'

const config = [
  { width: 0, items: 1 },
  { width: 700, items: 2 },
  { width: 1300, items: 3 },
]

export default ({ title, programs }) => {
  return (
    <Container>
      <Heading align="center">{title}</Heading>
      <Carousel
        breakpoints={config}
        slides={programs}
        interval={8000}
        dotComponent={Dot}
        buttonComponent={Button}
        slideRenderer={(cards) =>
          cards.map((slideProps, i) => (
            <ProgramCard
              key={i}
              image={
                slideProps.image.childImageSharp.gatsbyImageData.images.fallback
                  .src
              }
              title={slideProps.name}
              color={slideProps.color}
              description={slideProps.description}
              to={slideProps.link}
            />
          ))
        }
      />
    </Container>
  )
}

const Container = styled.div`
  margin: 0;
  padding: 40px 0 0;
  background: linear-gradient(45deg, #8b76ff, #624dd6);
`
const Carousel = styled(ResponsiveCarousel)`
  height: 490px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0 0 50px;

  @media (max-width: 1025px) {
    margin: 0 0 50px;
  }
`

const Dot = styled(ResponsiveCarousel.Dot)`
  margin: 0 2px;
  border: 1px solid white;
  background-color: ${(props) => (props.isActive ? 'white' : 'transparent')};
  width: 12px;
  height: 12px;
`
const Button = styled(ResponsiveCarousel.Button)`
  margin: 0 20px 30px;
  width: 50px;
  height: 50px;
  color: white;
  border-radius: none;
  border: none;
  background-color: transparent;
  display: inline-block;
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 5em;
  line-height: 0;
  text-align: center;
  transition: 0.3s ease;
  left: ${(props) => (props.left ? 0 : 'initial')};
  right: ${(props) => (props.right ? 0 : 'initial')};

  &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }

  &::after {
    content: ${(props) => (props.left ? `'\\02039'` : `'\\0203A'`)};
  }

  &:hover {
    color: white;
    border: none;
    background-color: transparent;
  }
`
