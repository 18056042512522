import React from 'react'
import styled, { keyframes, css } from 'styled-components'
import { transparentize } from 'polished'
import { Link } from 'gatsby'
import { fadeIn } from 'react-animations'

export default ({
  title,
  subtitle,
  buttonColor,
  buttonText,
  buttonLink,
  image,
}) => (
  <Slide image={image.childImageSharp.gatsbyImageData.images.fallback.src}>
    <Heading>{title}</Heading>
    <Divider />
    <SubHeading>{subtitle}</SubHeading>
    {buttonText && (
      <Button color={buttonColor} to={buttonLink}>
        {buttonText}
      </Button>
    )}
  </Slide>
)

const fadeAnimation = keyframes` ${fadeIn} `

// prettier-ignore
const overlayMixin = css`
  background:
    linear-gradient(
      ${props => transparentize(0.5, props.theme[props.overlayColor] || '#001c49')},
      ${props => transparentize(0.5, props.theme[props.overlayColor] || '#001c49')}
    ),
    url(${props => props.image}) no-repeat;
`

// prettier-ignore
const Slide = styled.div`
  ${overlayMixin}
  background-size: cover;
  background-position: center center;
  height: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Heading = styled.h1`
  font-family: ${(props) => props.theme.headingFont};
  color: white;
  text-align: center;
  font-size: 45pt;
  z-index: 25;
  animation: 1s ${fadeAnimation};

  @media (max-width: ${(props) => props.theme.phone}px) {
    font-size: 25pt;
  }
`
const Divider = styled.div`
  margin: 15px 0;
  width: 50px;
  height: 5px;
  background-color: white;
  z-index: 25;
  animation: 1s ${fadeAnimation};
`
const SubHeading = styled.h2`
  font-family: ${(props) => props.theme.bodyFont};
  color: white;
  font-weight: 200;
  text-align: center;
  font-size: 18pt;
  line-height: 26pt;
  width: 50%;
  z-index: 25;
  animation: 1s ${fadeAnimation};

  @media (max-width: ${(props) => props.theme.phone}px) {
    font-size: 14pt;
    line-height: 22pt;
    width: 80%;
  }
`
const Button = styled(Link)`
  margin: 25px 0;
  padding: 25px 45px;
  font-family: ${(props) => props.theme.navFont};
  font-size: 14pt;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
  line-height: 0;
  height: 40px;
  border-radius: 25px;
  background-color: ${(props) => props.theme[props.color]};
  border: none;
  transition: 0.5s;
  cursor: pointer;
  box-shadow: ${(props) => props.theme.boxShadow};
  z-index: 25;
  animation: 1s ${fadeAnimation};

  &:hover {
    color: ${(props) => props.theme[props.color]};
    background-color: white;
  }
`
