import React from 'react'
import { Carousel } from 'components/Carousel'
import ContentSlide from './HomeCarouselSlide'

export default ({ slides }) => (
  <Carousel
    slides={slides}
    interval={5000}
    dotComponent={Carousel.Dot}
    dotOffset={65}
    slideRenderer={slideProps => (
      <ContentSlide
        title={slideProps.title}
        subtitle={slideProps.subTitle}
        buttonColor={slideProps.buttonColor}
        buttonText={slideProps.buttonText}
        buttonLink={slideProps.link}
        image={slideProps.image}
      />
    )}
  />
)
