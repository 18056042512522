import React from 'react'

import OverlayLeft from 'components/OverlayLeft'
import Heading from 'components/Heading'
import Markdown from 'components/styled/Markdown'

export default ({ color, title, content, src, mobileImage }) => (
  <OverlayLeft src={src} mobileImage={mobileImage}>
    <Heading color={color}>{title}</Heading>
    <Markdown color={color} children={content} />
  </OverlayLeft>
)
