import React from 'react'
import styled from 'styled-components'
import { darken, transparentize } from 'polished'
import { Link } from 'gatsby'

export default ({ image, title, description, color, to }) => (
  <CardContainer image={image}>
    <CardImage image={image}>
      <Overlay color={color}>
        <Heading>{title}</Heading>
      </Overlay>
    </CardImage>
    <TxtBlock>{description}</TxtBlock>
    <LearnMore to={to} color={color}>
      Learn More
    </LearnMore>
  </CardContainer>
)

/* --------  STYLES  -------- */

const CardContainer = styled.div`
  margin: 25px;
  width: 350px;
  height: 410px;
  border-radius: 7px;
  box-shadow: 1px 20px 60px rgba(0, 0, 200, 0.25);
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 587px) {
    width: 300px;
    ${'' /* height: 300px; */};
  }
`
const CardImage = styled.div`
  width: 100%;
  height: 180px;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 6px 6px 0 0;
`
const Overlay = styled.div`
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => transparentize(0.2, props.theme[props.color])};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px 6px 0 0;
`
const Heading = styled.h2`
  margin: 20px 0 0;
  color: white;
  font-family: ${(props) => props.theme.navFont};
  font-size: 22pt;
  text-align: center;
  font-weight: 800;
  width: 100%;
`
const TxtBlock = styled.p`
  margin: 20px 30px;
  font-family: ${(props) => props.theme.bodyFont};
  font-size: 12pt;
  font-weight: 200;
  line-height: 20pt;
  color: grey;
  text-align: center;
`

const LearnMore = styled(Link)`
  display: block;
  padding: 25px 40px;
  margin: 0 0 35px;
  background-color: ${(props) => props.theme[props.color]};
  font-family: ${(props) => props.theme.navFont};
  font-size: 14pt;
  font-weight: 600;
  line-height: 0;
  color: white;
  border-radius: 40px;
  cursor: pointer;
  outline: none;
  transition-duration: 0.8s;
  opacity: 1;

  &:hover {
    background-color: ${(props) => darken(0.2, props.theme[props.color])};
  }
`
