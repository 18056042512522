import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import Seo from 'components/SEO'
import { graphql } from 'gatsby'

import OverlayLeftSection from 'components/OverlayLeftSection'
import HomeSlider from './HomeCarousel'
import Mission from './Mission'
import Programs from './Programs'

export default ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { seo, headerSlides, section1, section2, section3 } = frontmatter
  return (
    <Fragment>
      <Seo title={seo.title} description={seo.description} />
      <Helmet title={seo.title} />
      <HomeSlider slides={headerSlides} />
      <OverlayLeftSection
        color="purple"
        title={section1.title}
        content={section1.description}
        src={section1.image.childImageSharp.gatsbyImageData.images.fallback.src}
        mobileImage="hide"
      />
      <Mission
        color="pink"
        title={section2.title}
        content={section2.description}
        src={section2.image.childImageSharp.gatsbyImageData.images.fallback.src}
      />
      <Programs title={section3.title} programs={section3.programs} />
    </Fragment>
  )
}

export const homeQuery = graphql`
  query HomePageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seo {
          title
          description
        }
        headerSlides {
          title
          subTitle
          link
          buttonText
          buttonColor
          image {
            childImageSharp {
              gatsbyImageData(sizes: "2200")
            }
          }
        }
        section1 {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        section2 {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        section3 {
          title
          programs {
            name
            description
            link
            color
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
